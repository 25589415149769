import './_imageCrop.scss';
import React, {useState, useRef} from 'react';
import ReactCrop, {centerCrop, makeAspectCrop, Crop, PixelCrop} from 'react-image-crop';
import {Button} from '../../components/button/ui/Button';
import {FileInput} from '../../components/file-input/ui/FileInput';
import {CroppedImage} from '../types';
import {getCroppedImage} from '../utils/getCroppedImage';

interface ImageCropProps {
    imgSrc?: string;
    aspect?: number;
    buttonName: string;
    onGetCroppedImage: (croppedImage: CroppedImage) => void;
}

export function ImageCrop({imgSrc: imageSrc, aspect = 1, onGetCroppedImage, buttonName}: ImageCropProps) {
    const [imgSrc, setImgSrc] = useState(imageSrc);
    const imgRef = useRef<HTMLImageElement>(null);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined); // Makes crop preview update between images.
            const reader = new FileReader();
            reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const {width, height} = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }

    function onSubmitCrop() {
        if (completedCrop) {
            const croppedImage: CroppedImage = getCroppedImage(imgRef.current, completedCrop, 1);
            if (croppedImage) {
                onGetCroppedImage(croppedImage);
            }
        }
    }

    return (
        <div className="mp-image-crop">
            <div className="mp-image-crop-header">
                <FileInput accept="image/*" onChange={onSelectFile} />
            </div>
            <div className="mp-image-crop-image">
                {!!imgSrc && (
                    <ReactCrop
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={aspect}
                        minWidth={100}
                        minHeight={100}
                    >
                        <img ref={imgRef} alt="Loading" src={imgSrc} onLoad={onImageLoad} />
                    </ReactCrop>
                )}
            </div>
            <div className="mp-image-crop-footer">{completedCrop && <Button title={buttonName} onClick={onSubmitCrop} />}</div>
        </div>
    );
}

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
    return centerCrop(makeAspectCrop({unit: '%', width: 100}, aspect, mediaWidth, mediaHeight), mediaWidth, mediaHeight);
}
