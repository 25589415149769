import {Button} from '@mp/common/components/button/ui/Button';
import {Icon, SvgButton} from '@mp/common/svg';
import classNames from 'classnames';
import React from 'react';
import {TileItemParams, tileItems} from '../../../components/header/tileItems';

type SettingTileItem = TileItemParams & {isSelected: boolean; order: number};

const MAX_ITEMS_IN_TOOLBAR: number = 7;

interface TileItemsSettingsState {
    tileItems: Array<SettingTileItem>;
    itemToMove: SettingTileItem;
}

interface TileItemsSettingsProps {
    isHome?: boolean;
    tileItemIds: Array<number>;
    onSaveClick: (data: string) => void;
}

export class TileItemsSettings extends React.Component<TileItemsSettingsProps, TileItemsSettingsState> {
    private tileItemsItemOrder = null;

    constructor(props: TileItemsSettingsProps) {
        super(props);

        this.tileItemsItemOrder = this.props.tileItemIds.length + 1;

        const settingTileItems: Array<SettingTileItem> = tileItems
            .getAllTitleItems()
            .filter(({isHome}) => (this.props.isHome ? !isHome : true))
            .map((item) => ({
                ...item,
                order: this.props.tileItemIds.indexOf(item.id),
                isSelected: this.props.tileItemIds.includes(item.id)
            }));

        this.state = {tileItems: settingTileItems, itemToMove: null};
    }

    public render() {
        const {tileItems: tileItemsState, itemToMove} = this.state;

        return (
            <div className="tile-items-settings">
                <div className="tile-items-settings-grid">
                    {tileItemsState.map((item) => (
                        <div key={item.id} onClick={() => this.onTileItemClick(item)}>
                            <SvgButton className={this.resolveButtonClass(item.isSelected)} icon={item.svgIcon} title={item.title} />
                        </div>
                    ))}
                </div>
                <div className="tile-items-settings-tiles-title">
                    <span>{this.resolvePreviewName()}</span>
                </div>
                <div className="tile-items-settings-tiles-preview">
                    {this.getSelectedTileItemsInOrder().map((item) => {
                        return (
                            <SvgButton
                                key={item.id}
                                className={this.resolveButtonClass(item.id === itemToMove?.id)}
                                icon={item.svgIcon}
                                onClick={() => this.onPreviewTileItemClick(item)}
                            />
                        );
                    })}
                </div>
                {itemToMove && (
                    <div className="tile-items-settings-action-panel">
                        <SvgButton icon={Icon.AngleLeft} onClick={() => this.onMoveItem(-1)} />
                        <SvgButton
                            icon={Icon.TrashCan}
                            onClick={() => {
                                const tempItems = tileItemsState;
                                const item: SettingTileItem = tempItems.find(({id}) => id === itemToMove.id);
                                item.isSelected = false;
                                this.setState({tileItems: tempItems, itemToMove: null});
                            }}
                        />
                        <SvgButton icon={Icon.AngleRight} onClick={() => this.onMoveItem(1)} />
                    </div>
                )}
                <div className="tile-items-settings-footer">
                    <Button
                        title="Zapisz"
                        onClick={() => this.props.onSaveClick(JSON.stringify(this.getSelectedTileItemsInOrder().map(({id}) => id)))}
                    />
                </div>
            </div>
        );
    }

    private onMoveItem(nextValue: number): void {
        const sortedItems: Array<SettingTileItem> = this.getSelectedTileItemsInOrder();
        const currentItemIndex: number = sortedItems.indexOf(this.state.itemToMove);
        const nextItemId: number = sortedItems[currentItemIndex + nextValue]?.id;

        if (nextItemId) {
            const tempItems = this.state.tileItems;
            const thisItem: SettingTileItem = tempItems.find(({id}) => id === this.state.itemToMove.id);
            const nextItem: SettingTileItem = tempItems.find(({id}) => id === nextItemId);
            if (nextItem.isHome) {
                return;
            }

            let tempOrder: number = null;
            tempOrder = nextItem.order;
            nextItem.order = thisItem.order;
            thisItem.order = tempOrder;
            this.setState({tileItems: tempItems});
        }
    }

    private resolveButtonClass(isSelected: boolean = false): string {
        return classNames({'home-item': this.props.isHome, 'toolbar-item': !this.props.isHome, 'selected-item': isSelected});
    }

    private resolvePreviewName(): string {
        if (this.props.isHome) {
            return 'podgląd:';
        }
        return `podgląd (${this.getSelectedTileItemsInOrder().length}/${MAX_ITEMS_IN_TOOLBAR}):`;
    }

    private onTileItemClick(settingItem: SettingTileItem): void {
        if ((!settingItem.isSelected && this.getSelectedTileItemsInOrder().length < MAX_ITEMS_IN_TOOLBAR) || this.props.isHome) {
            const tempItems = this.state.tileItems;
            const item: SettingTileItem = tempItems.find(({id}) => id === settingItem.id);
            item.isSelected = true;
            item.order = ++this.tileItemsItemOrder;

            this.setState({tileItems: tempItems});
        }
    }

    private onPreviewTileItemClick(settingItem: SettingTileItem): void {
        if (!settingItem.isHome) {
            this.setState({itemToMove: settingItem});
        }
    }

    private getSelectedTileItemsInOrder(): Array<SettingTileItem> {
        return this.state.tileItems.filter((f) => f.isSelected).sort((a, b) => a.order - b.order);
    }
}
