import {Icon} from '@mp/common/svg';
import {currentDate} from '@mp/common/utils/date';
import {ExpensesTab, RouteName, Router, ShoppingListTab, WishListTab, WorkHoursTab} from '@mp/route';
import {ORIGIN} from '../../global';

export interface TileItemParams {
    id: number;
    isHome?: boolean;
    svgIcon: Icon;
    title: string;
    route: string;
}

class TileItemsContainer {
    public getAllTitleItems(): Array<TileItemParams> {
        const items = [
            this.Home,
            this.Calendar,
            this.Expenses,
            this.Series,
            this.ShoppingList,
            this.TravelPayments,
            this.BucketList,
            this.CinemaProgram,
            this.Movies,
            this.WorkHours,
            this.Invoices,
            this.Links,
            this.Sushi,
            this.BusinessCard,
            this.ShoppingCard,
            this.Debtors,
            this.Games,
            this.Recipes,
            this.ApartmentExpenses,
            this.WinesRanking,
            this.WishList,
            this.Files,
            this.CheckList,
            this.Weather,
            this.WeddingList,
            this.Subscriptions,
            this.Bullseye,
            this.CashEnvelopes,
            this.Spices,
            this.CostsEstimate
        ];

        const idsArray: Array<number> = items.map((x) => x.id);
        if (new Set(idsArray).size !== idsArray.length) {
            throw new Error('Id has to be unique');
        }

        return items;
    }

    get Home(): TileItemParams {
        return {id: 1, svgIcon: Icon.HouseChimney, isHome: true, title: 'Home', route: RouteName.HOME};
    }

    get Calendar(): TileItemParams {
        const date: Date = new Date();
        return {
            id: 2,
            svgIcon: Icon.CalendarDays,
            title: 'Kalendarz',
            route: Router.getUrlToCalendarPage({year: date.getFullYear(), month: date.getMonth()})
        };
    }

    get Expenses(): TileItemParams {
        return {
            id: 3,
            svgIcon: Icon.MoneyBill,
            title: 'Wydatki',
            route: Router.getUrlToExpensesPage(ExpensesTab.MonthSummary)
        };
    }

    get Series(): TileItemParams {
        return {
            id: 4,
            svgIcon: Icon.Film,
            title: 'Seriale',
            route: Router.getUrlToEpisodesPage('active')
        };
    }

    get ShoppingList(): TileItemParams {
        return {
            id: 5,
            svgIcon: Icon.CartShopping,
            title: 'Zakupy',
            route: Router.getUrlToShoppingListPage(ShoppingListTab.COMMON)
        };
    }

    get TravelPayments(): TileItemParams {
        return {
            id: 6,
            svgIcon: Icon.Plane,
            title: 'Podróże',
            route: Router.getUrlToTravelPaymentPage()
        };
    }

    get BucketList(): TileItemParams {
        return {
            id: 7,
            svgIcon: Icon.HeartFilled,
            title: 'Bucket lista',
            route: RouteName.BUCKET_LIST
        };
    }

    get CinemaProgram(): TileItemParams {
        return {
            id: 8,
            svgIcon: Icon.Star,
            title: 'Repertuar',
            route: Router.getUrlToCinemaProgramPage()
        };
    }

    get Movies(): TileItemParams {
        return {
            id: 9,
            svgIcon: Icon.Video,
            title: 'Filmy',
            route: Router.getUrlToMoviePage('search')
        };
    }

    get WorkHours(): TileItemParams {
        return {
            id: 10,
            svgIcon: Icon.BusinessTime,
            title: 'Praca',
            route: Router.getUrlToWorkHoursPage(WorkHoursTab.Table)
        };
    }

    get Invoices(): TileItemParams {
        return {
            id: 11,
            svgIcon: Icon.FileInvoiceDollar,
            title: 'Faktury',
            route: Router.getUrlToInvoicesPage()
        };
    }

    get Links(): TileItemParams {
        return {
            id: 14,
            svgIcon: Icon.Link,
            title: 'Linki',
            route: Router.getUrlToLinksPage()
        };
    }

    get Sushi(): TileItemParams {
        return {id: 15, svgIcon: Icon.FishFins, title: 'Sushi', route: RouteName.SUSHI};
    }

    get BusinessCard(): TileItemParams {
        return {
            id: 16,
            svgIcon: Icon.IdCard,
            title: 'Wizytówki',
            route: RouteName.BUSINESS_CARD
        };
    }

    get ShoppingCard(): TileItemParams {
        return {
            id: 17,
            svgIcon: Icon.CreditCard,
            title: 'Karty stałego klienta',
            route: RouteName.DISCOUNT_CARDS
        };
    }

    get Debtors(): TileItemParams {
        return {
            id: 18,
            svgIcon: Icon.HandHoldingDollar,
            title: 'Dłużnicy',
            route: RouteName.DEBTORS
        };
    }

    get Games(): TileItemParams {
        return {id: 19, svgIcon: Icon.Gamepad, title: 'Gry', route: RouteName.GAMES};
    }

    get Recipes(): TileItemParams {
        return {
            id: 20,
            svgIcon: Icon.Utensils,
            title: 'Przepisy',
            route: Router.getUrlToRecipesPage()
        };
    }

    get ApartmentExpenses(): TileItemParams {
        return {
            id: 21,
            svgIcon: Icon.DollarSign,
            title: 'Mieszkanie',
            route: Router.getUrlToApartmentExpensesPage({year: currentDate().getFullYear()})
        };
    }

    get WishList(): TileItemParams {
        return {
            id: 22,
            svgIcon: Icon.WandSparkles,
            title: 'Lista życzeń',
            route: Router.getUrlToWishListPage(WishListTab.TO_BUY)
        };
    }

    get Files(): TileItemParams {
        return {
            id: 23,
            svgIcon: Icon.FolderOpen,
            title: 'Pliki',
            route: RouteName.FILES
        };
    }

    get CheckList(): TileItemParams {
        return {
            id: 24,
            svgIcon: Icon.ListCheck,
            title: 'Check lista',
            route: Router.getUrlToChecklistPage()
        };
    }

    get WeddingList(): TileItemParams {
        return {
            id: 25,
            svgIcon: Icon.Ring,
            title: 'Strona ślubna',
            route: 'https://www.k-m-slub.pl/'
        };
    }

    get WinesRanking(): TileItemParams {
        return {
            id: 27,
            svgIcon: Icon.WineBottle,
            title: 'Ranking win',
            route: Router.getUrlToWinesRankingPage()
        };
    }

    get Weather(): TileItemParams {
        return {
            id: 28,
            svgIcon: Icon.Sun,
            title: 'Pogoda Gdańsk',
            route: `${ORIGIN}/api/meteo`
        };
    }

    get Subscriptions(): TileItemParams {
        return {
            id: 29,
            svgIcon: Icon.DollarSign,
            title: 'Subskrypcje',
            route: Router.getUrlToSubscriptionsPage()
        };
    }

    get Bullseye(): TileItemParams {
        return {
            id: 30,
            svgIcon: Icon.Bullseye,
            title: 'Dart',
            route: Router.getUrlToDartsPage()
        };
    }

    get CashEnvelopes(): TileItemParams {
        return {
            id: 31,
            svgIcon: Icon.Envelope,
            title: 'Koperty',
            route: Router.getUrlToCashEnvelopesPage()
        };
    }

    get Spices(): TileItemParams {
        return {
            id: 32,
            svgIcon: Icon.MortarPestle,
            title: 'Przyprawy',
            route: Router.getUrlToSpicesPage()
        };
    }

    get CostsEstimate(): TileItemParams {
        return {
            id: 33,
            svgIcon: Icon.RectangleList,
            title: 'Kosztorysy',
            route: Router.getUrlToCostsEstimate({})
        };
    }
}

export const tileItems: TileItemsContainer = new TileItemsContainer();
