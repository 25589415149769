import {useNumberQueryParams} from '@mp/common/hooks/useQueryParams';
import {Icon, SvgIcon} from '@mp/common/svg';
import {isEmpty} from '@mp/common/utils/array';
import {toCurrency} from '@mp/common/utils/number';
import {Router} from '@mp/route';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {CostEstimateItem, CostEstimateSection} from '../types';
import {createIdForItem, createIdForItemRef, createIdForSection} from '../utils/idGenerator';
import {scrollTo} from '../utils/scroll';

interface CostsEstimateSectionParams {
    section: CostEstimateSection;
    isEditMode: boolean;
    onEditSection: (section: CostEstimateSection) => void;
    onEditItem: (item: CostEstimateItem) => void;
}

export function CostsEstimateSection({section, isEditMode, onEditSection, onEditItem}: CostsEstimateSectionParams): JSX.Element {
    const [isOpened, setIsOpened] = useState(false);

    const isSectionDataMissing = (section: CostEstimateSection): boolean => {
        if (!section.estimatedCost) {
            return true;
        }
        return !!section.items.find(({warning}) => warning);
    };

    const resolveSectionCost = (section: CostEstimateSection): string => {
        return section.realCost ? toCurrency(section.realCost) : '~' + toCurrency(section.estimatedCost);
    };

    return (
        <div key={section.id} className="section" id={createIdForSection(section.id)}>
            <div className="section-header" onClick={() => setIsOpened(!isOpened)}>
                <span className="section-header-title">
                    {isEditMode && <SvgIcon icon={Icon.Pencil} onClick={() => onEditSection(section)} />}
                    {isSectionDataMissing(section) && <SvgIcon icon={Icon.TriangleExclamation} />}
                    <span>{section.name}</span>
                </span>
                <span className="section-header-cost">{resolveSectionCost(section)}</span>
            </div>
            {isOpened && (
                <div className="section-body">
                    {isEmpty(section.items) && <div className="item">{`<brak danych>`}</div>}
                    {section.items.map((item) => (
                        <div id={createIdForItem(item.id)} key={item.id} className="item">
                            <div className="item-left">
                                <div>
                                    {isEditMode && (
                                        <Link
                                            to={Router.getUrlToCostsEstimate({
                                                listId: useNumberQueryParams('listId'),
                                                editImageId: item.id,
                                                imgSrc: item.img
                                            })}
                                        >
                                            <SvgIcon icon={Icon.CameraRetro} />
                                        </Link>
                                    )}
                                    {isEditMode && <SvgIcon icon={Icon.Pencil} onClick={() => onEditItem(item)} />}
                                    {item.warning && (
                                        <SvgIcon
                                            onClick={() => handleWarningClick(createIdForItemRef(item.id))}
                                            icon={Icon.TriangleExclamation}
                                        />
                                    )}
                                    {item.name}
                                </div>
                                <div className="item-left-details">
                                    <div>{toCurrency(item.price)}</div>
                                    {item.symbol && (
                                        <a href={`https://www.google.pl/search?q=${item.symbol}`} target="_blank" rel="noreferrer">
                                            {item.symbol}
                                        </a>
                                    )}
                                </div>
                            </div>
                            <div className="item-right">
                                {item.img && (
                                    <a href={item.img} target="_blank" rel="noreferrer">
                                        <img alt={item.name} src={item.img} />
                                    </a>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

function handleWarningClick(id: string): void {
    scrollTo(document.getElementById(id));
}
