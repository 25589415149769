import {Button} from '@mp/common/components/button/ui/Button';
import {CheckBox} from '@mp/common/components/check-box/ui/CheckBox';
import {TextInput} from '@mp/common/components/text-input/ui/TextInput';
import React from 'react';

// eslint-disable-next-line
type AnyValue = any;

interface CustomInputProps {
    title: string;
    defaultValue: string | number | boolean;
    type: 'checkbox' | 'numeric' | 'text';
    onSave: (value: AnyValue) => void;
}

export class CustomInput extends React.Component<CustomInputProps> {
    private inputElement: HTMLInputElement;

    constructor(props: CustomInputProps) {
        super(props);

        this.state = {};
    }

    public render() {
        return (
            <div className="custom-setting-input">
                <div className="custom-setting-input-left">
                    <span>{this.props.title}</span>
                </div>
                <div className="custom-setting-input-right">
                    {this.resolveInput()}
                    <Button title="Zapisz" onClick={() => this.props.onSave(this.resolveValue())} />
                </div>
            </div>
        );
    }

    private resolveValue(): AnyValue {
        switch (this.props.type) {
            case 'checkbox':
                return this.inputElement.checked ? '1' : '0';
            case 'text':
                return this.inputElement.value;
            default:
                throw new Error();
        }
    }

    private resolveInput(): JSX.Element {
        switch (this.props.type) {
            case 'checkbox':
                return (
                    <CheckBox
                        defaultChecked={this.props.defaultValue as boolean}
                        reference={(input: HTMLInputElement) => (this.inputElement = input)}
                    />
                );
            case 'text':
                return (
                    <TextInput
                        defaultValue={this.props.defaultValue.toString()}
                        reference={(input: HTMLInputElement) => (this.inputElement = input)}
                    />
                );
            case 'numeric':
            default:
                throw new Error();
        }
    }
}
