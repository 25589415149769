export function createIdForSection(id: number): string {
    return `ce-section-${id}`;
}

export function createIdForItem(id: number): string {
    return `ce-item-${id}`;
}

export function createIdForItemRef(id: number): string {
    return `rce-item-${id}`;
}
