import './_debtorsPage.scss';
import {Color} from '@mp/common/colors';
import {Button} from '@mp/common/components/button/ui/Button';
import {SimpleToolBar} from '@mp/common/components/tool-bar';
import {ViewMode} from '@mp/common/consts';
import {Logger} from '@mp/common/logger';
import {ConfirmModal} from '@mp/common/modals/ConfirmModal';
import {FormsModal} from '@mp/common/modals/FormsModal';
import {Icon, SvgButton, SvgIcon} from '@mp/common/svg';
import {isEmpty} from '@mp/common/utils/array';
import {toDateString} from '@mp/common/utils/converters';
import classNames from 'classnames';
import React from 'react';
import {AccordionItem} from '../../../components/accordion/AccordionItem';
import {Loading} from '../../../components/loading';
import {isAdmin} from '../../../global';
import {addDebtorsService, deleteDebtorsService, loadDebtorsService, updateDebtorsService} from '../services';
import {Debt, Debtor} from '../types';
import {getInputsData} from './utils/getInputsData';

const NO_DEBTORS: string = 'Brak dłużników';

interface DebtorsPageState {
    isLoaded: boolean;
    modal: ViewMode;
    debtors: Array<Debtor>;
    selectedItem: Debt;
    debtorName: string;
    debtToDelete: Debt;
    debtorsEditMode: boolean;
}

let _debtors: Array<Debtor>;

export function DebtorsPage(): JSX.Element {
    return <DebtorsPageCC />;
}

class DebtorsPageCC extends React.Component<object, DebtorsPageState> {
    constructor(props: object) {
        super(props);

        this.state = {
            isLoaded: false,
            modal: null,
            selectedItem: null,
            debtToDelete: null,
            debtors: null,
            debtorName: null,
            debtorsEditMode: false
        };
    }

    public componentDidMount() {
        this.loadData(false);
    }

    public render() {
        const {isLoaded} = this.state;

        if (!isLoaded) {
            return <Loading />;
        }

        return (
            <div className={'mp-debtors-page'}>
                {isAdmin() && (
                    <SimpleToolBar
                        name="Dłużnicy"
                        backgroundColor={Color.BlueLight}
                        onAddClick={() => this.setState({modal: ViewMode.Add, selectedItem: null})}
                    />
                )}

                {this.renderDebtors()}
                {this.renderAddDebtorModal()}
                {this.renderEditDebtorModal()}
                {this.renderDeleteDebtModal()}
            </div>
        );
    }

    private loadData(forceCallService: boolean = true) {
        if (forceCallService || _debtors == null) {
            loadDebtorsService()
                .then((res) => {
                    _debtors = res;
                    updateState(this, _debtors);
                })
                .catch((error) => {
                    this.setState({isLoaded: true});
                    Logger.wholeError(error);
                });
        } else {
            updateState(this, _debtors);
        }

        function updateState(_this: DebtorsPageCC, debtors: Array<Debtor>): void {
            _this.setState({
                debtors,
                isLoaded: true,
                modal: null,
                selectedItem: null,
                debtorsEditMode: isAdmin() ? _this.state.debtorsEditMode : false
            });
        }
    }

    private setNormalView(): void {
        this.setState({modal: null, selectedItem: null, debtorName: null});
    }

    private renderAddDebtorModal(): JSX.Element {
        const {modal, debtorName} = this.state;

        return (
            modal === ViewMode.Add && (
                <FormsModal<Debt>
                    title="Dodaj dłużnika"
                    mode="add"
                    inputs={getInputsData({debtorName})}
                    handleClose={() => this.setNormalView()}
                    onSendForm={(data) => addDebtorsService(data).then(() => this.loadData())}
                />
            )
        );
    }

    private renderEditDebtorModal(): JSX.Element {
        const {modal, selectedItem} = this.state;

        return (
            modal === ViewMode.Edit && (
                <FormsModal<Debt>
                    title="Edytuj dług"
                    mode="update"
                    inputs={getInputsData({selectedItem})}
                    handleClose={() => this.setNormalView()}
                    onSendForm={(data) => updateDebtorsService(data).then(() => this.loadData())}
                />
            )
        );
    }

    private renderDeleteDebtModal(): JSX.Element {
        const {debtToDelete} = this.state;
        if (debtToDelete) {
            const {dateFrom, value, name} = debtToDelete;
            return (
                <ConfirmModal
                    title={`Usuwanie długu (${name})`}
                    question={`Czy na pewno chcesz usunąć dług ${value} zł z dnia ${toDateString(dateFrom, 'dd-mm-yyyy')}`}
                    handleConfirm={() => this.removeDebtorService(debtToDelete)}
                    handleClose={() => this.setState({debtToDelete: null})}
                />
            );
        }
    }

    private removeDebtorService(_debt: Debt) {
        const {id} = _debt;
        deleteDebtorsService(id).then((result) => {
            if (result.success) {
                const debtorsTemp: Array<Debtor> = this.state.debtors;
                debtorsTemp.forEach((debtor) => {
                    const index: number = debtor.debts.findIndex((debt) => debt.id === id);
                    if (index >= 0) {
                        debtor.debts[index] = null;
                        debtor.debts = debtor.debts.filter((d) => d); // todo
                    }
                });
                this.setState({debtors: debtorsTemp, debtToDelete: null});
            } else {
                this.setState({debtToDelete: null});
            }
        });
    }

    private renderDebtors(): JSX.Element {
        const {debtors} = this.state;

        if (isEmpty(debtors)) {
            return <div>{NO_DEBTORS}</div>;
        }

        return (
            <div>
                {debtors.map((debtor: Debtor) => {
                    const header: JSX.Element = (
                        <div className={'accordion-custom-header'}>
                            <span>
                                <SvgButton
                                    icon={Icon.Plus}
                                    onClick={(event) => (isAdmin() ? this.onAddDebtToDebtorClick(debtor, event) : null)}
                                />
                            </span>
                            <span>{debtor.name}</span>
                            <span>{toDateString(debtor.date)}</span>
                            <span>{getTotalValue(debtor)}</span>
                        </div>
                    );
                    return (
                        <AccordionItem key={debtor.name} header={header}>
                            {this.renderDebtorsEditModeButton()}
                            {this.getDebts(debtor)}
                        </AccordionItem>
                    );
                })}
            </div>
        );

        function getTotalValue(debtor: Debtor): string {
            let debt: number = 0;
            debtor.debts.forEach((d) => (debt += d.value));
            return `${debt.toFixed(2)} zł`;
        }
    }

    private renderDebtorsEditModeButton(): JSX.Element {
        if (isAdmin()) {
            if (this.state.debtorsEditMode) {
                return <Button title="Włącz tryb dat" onClick={() => this.setState({debtorsEditMode: false})} />;
            }
            return <Button title="Włącz tryb edycji" onClick={() => this.setState({debtorsEditMode: true})} />;
        }
        return null;
    }

    private onAddDebtToDebtorClick(debtor: Debtor, event: React.MouseEvent): void {
        event.stopPropagation();
        this.setState({modal: ViewMode.Add, selectedItem: null, debtorName: debtor.name});
    }

    private getDebts(debtor: Debtor): Array<JSX.Element> {
        const debtElements: Array<JSX.Element> = [];
        debtor.debts.forEach((debt: Debt) => {
            const value: string = debt.value.toFixed(2) + ' zł';
            debtElements.push(
                <div key={debt.id} className={classNames('debt-row', {'date-mode': !this.state.debtorsEditMode})}>
                    <div className={`debt-row-description`}>{debt.description}</div>
                    <div className={`debt-row-value`}>{value}</div>
                    {this.state.debtorsEditMode ? (
                        <>
                            <SvgIcon icon={Icon.Pencil} onClick={() => this.setState({modal: ViewMode.Edit, selectedItem: debt})} />
                            <SvgIcon icon={Icon.TrashCan} onClick={() => this.setState({debtToDelete: debt})} />
                        </>
                    ) : (
                        <span style={{minWidth: '80px', textAlign: 'center', whiteSpace: 'nowrap'}}>{toDateString(debt.dateFrom)}</span>
                    )}
                </div>
            );
        });
        return debtElements;
    }
}
