import './_index.scss';
import {Button} from '@mp/common/components/button/ui/Button';
import {TextInput} from '@mp/common/components/text-input/ui/TextInput';
import {Modal} from '@mp/common/modals/Modal';
import {Icon, SvgIcon} from '@mp/common/svg';
import {isEmpty} from '@mp/common/utils/array';
import {minDigits} from '@mp/common/utils/number';
import {AxiosError} from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import {Loading} from '../../components/loading';
import {ERROR_CLASS, RowComponent} from '../../components/row';
import {loadIKnowCardsService, updateIKnowSubCardService} from './services';
import {IKNOW_CARDS_NUMBER, IKnowCard, IKnowCategory, IKnowSubCard, resolveCategoryColor, View} from './types';
import {isCardDataCompleted, isSubCardDataCompleted} from './utils';
import {ClueIcon, DotNumber} from './utils/ClueIcon';

export interface GamesPageState {
    isLoaded: boolean;
    viewMode: View;
    selectedCardNumber: number;
    cards: Array<IKnowCard>;
}

export class GamesPage extends React.Component<object, GamesPageState> {
    constructor(props: object) {
        super(props);

        this.state = {
            isLoaded: false,
            viewMode: View.Normal,
            selectedCardNumber: null,
            cards: null
        };
    }

    componentDidMount() {
        this.loadData();
    }

    private loadData(): void {
        loadIKnowCardsService()
            .then((res) => this.setState({isLoaded: true, viewMode: View.Normal, cards: res}))
            .catch(() => this.setState({isLoaded: true}));
    }

    render() {
        const {isLoaded, cards, viewMode, selectedCardNumber} = this.state;
        if (!isLoaded || isEmpty(cards)) {
            return <Loading />;
        }
        if (viewMode === View.PreviewCard) {
            const card: IKnowCard = this.getCard(selectedCardNumber);
            if (isCardDataCompleted(card)) {
                return (
                    <mp-games-page>
                        <Modal size="big" title={`Podgląd karty ${card.number}`} handleClose={() => this.setNormalView()}>
                            {this.renderCardPreview(card)}
                        </Modal>
                    </mp-games-page>
                );
            } else {
                return <mp-games-page>{this.renderEditCardModal()}</mp-games-page>;
            }
        }
        return <mp-games-page>{this.renderCardButtons()}</mp-games-page>;
    }

    private addErrorMessage(selector: string, errorMessage: string): void {
        // eslint-disable-next-line react/no-find-dom-node
        (ReactDOM.findDOMNode(this) as HTMLElement).querySelector(`.${selector} .${ERROR_CLASS}`).innerHTML = errorMessage;
    }

    private renderEditCardModal(): JSX.Element {
        const cardState: IKnowCard = this.getCard(this.state.selectedCardNumber);

        return (
            <Modal size="big" title={`Dodaj kartę (${minDigits(cardState.number, 3)})`} handleClose={() => this.setNormalView()}>
                <div className="add-card-modal">
                    {this.renderSubCardEdit(cardState.people, resolveCategoryColor(IKnowCategory.PEOPLE), cardState.number)}
                    {this.renderSubCardEdit(cardState.world, resolveCategoryColor(IKnowCategory.WORLD), cardState.number)}
                    {this.renderSubCardEdit(cardState.history, resolveCategoryColor(IKnowCategory.HISTORY), cardState.number)}
                    {this.renderSubCardEdit(cardState.culture, resolveCategoryColor(IKnowCategory.CULTURE), cardState.number)}
                </div>
            </Modal>
        );
    }

    private renderSubCardEdit(subCard: IKnowSubCard, color: string, cardNumber: number): JSX.Element {
        const questionInput: React.RefObject<HTMLInputElement> = React.createRef();
        const clueInputNo1: React.RefObject<HTMLInputElement> = React.createRef();
        const clueInputNo2: React.RefObject<HTMLInputElement> = React.createRef();
        const clueInputNo3: React.RefObject<HTMLInputElement> = React.createRef();
        const answerInput: React.RefObject<HTMLInputElement> = React.createRef();

        const questionClass: string = 'question' + subCard.id;
        const clueClassNo3: string = 'clue-3' + subCard.id;
        const clueClassNo2: string = 'clue-2' + subCard.id;
        const clueClassNo1: string = 'clue-1' + subCard.id;
        const answerClass: string = 'answer' + subCard.id;
        const categoryClass: string = 'category' + subCard.id;

        return (
            <div key={subCard.id} className="iknow-card-edit">
                <RowComponent rowClass={questionClass}>
                    <TextInput
                        title={'Pytanie'}
                        reference={questionInput}
                        defaultValue={subCard.question}
                        spellCheck={true}
                        disabled={!!subCard.question}
                        autoFocus={true}
                    />
                </RowComponent>
                <RowComponent rowClass={clueClassNo3}>
                    <TextInput
                        reference={clueInputNo3}
                        title={this.pointTitle(3)}
                        defaultValue={subCard.clue_3}
                        spellCheck={true}
                        disabled={!!subCard.clue_3}
                    />
                </RowComponent>
                <RowComponent rowClass={clueClassNo2}>
                    <TextInput
                        reference={clueInputNo2}
                        title={this.pointTitle(2)}
                        defaultValue={subCard.clue_2}
                        spellCheck={true}
                        disabled={!!subCard.clue_2}
                    />
                </RowComponent>
                <RowComponent rowClass={clueClassNo1}>
                    <TextInput
                        reference={clueInputNo1}
                        title={this.pointTitle(1)}
                        defaultValue={subCard.clue_1}
                        spellCheck={true}
                        disabled={!!subCard.clue_1}
                    />
                </RowComponent>
                <RowComponent rowClass={answerClass}>
                    <TextInput
                        reference={answerInput}
                        title={'Odpowiedź'}
                        defaultValue={subCard.answer}
                        spellCheck={true}
                        disabled={!!subCard.answer}
                    />
                </RowComponent>
                <RowComponent rowClass={categoryClass}>
                    <TextInput defaultValue="" title={'Kategoria'} style={{backgroundColor: color}} disabled={true} />
                </RowComponent>
                {!isSubCardDataCompleted(subCard) && (
                    <Button
                        title="Aktualizuj"
                        onClick={() => {
                            let isValid: boolean = true;
                            const minSigns: string = 'Minimum 5 znaków';

                            // eslint-disable-next-line react/no-find-dom-node
                            Array.from((ReactDOM.findDOMNode(this) as HTMLElement).querySelectorAll(`.${ERROR_CLASS}`)).map(
                                (x) => (x.innerHTML = '')
                            );

                            if (questionInput.current.value.length < 5) {
                                isValid = false;
                                this.addErrorMessage(questionClass, minSigns);
                            }
                            if (answerInput.current.value.length < 1) {
                                isValid = false;
                                this.addErrorMessage(answerClass, 'Pole obowiązkowe');
                            }

                            if (isValid) {
                                this.updateCard(
                                    {
                                        id: subCard.id,
                                        question: questionInput.current.value.trim(),
                                        clue_3: clueInputNo3.current.value.trim(),
                                        clue_2: clueInputNo2.current.value.trim(),
                                        clue_1: clueInputNo1.current.value.trim(),
                                        answer: answerInput.current.value.trim()
                                    },
                                    cardNumber
                                );
                            }
                        }}
                    />
                )}
            </div>
        );
    }

    private pointTitle(num: number): string {
        return `Podpowiedź za ${num} punkt` + (num === 1 ? '' : 'y');
    }

    private setNormalView(): void {
        this.setState({viewMode: View.Normal, selectedCardNumber: null});
    }

    private renderCardPreview(card: IKnowCard): JSX.Element {
        return (
            <div className="preview-card-modal">
                {this.renderSubCardPreview(card.people, card.number, 'blue')}
                {this.renderSubCardPreview(card.world, card.number, 'green')}
                {this.renderSubCardPreview(card.history, card.number, 'red')}
                {this.renderSubCardPreview(card.culture, card.number, 'yellow')}
            </div>
        );
    }

    private renderSubCardPreview(card: IKnowSubCard, cardNumber: number, color: string): JSX.Element {
        return (
            <div
                onClick={() =>
                    this.setState({
                        selectedCardNumber: this.state.selectedCardNumber + 1
                    })
                }
                className="preview"
            >
                <div className="question">{card.question}</div>
                <div className="c3">
                    <ClueIcon dotNumber={DotNumber.THREE} color={color} />
                    <span>{card.clue_3}</span>
                </div>
                <div className="c2">
                    <ClueIcon dotNumber={DotNumber.TWO} color={color} />
                    <span>{card.clue_2}</span>
                </div>
                <div className="c1">
                    <ClueIcon dotNumber={DotNumber.ONE} color={color} />
                    <span>{card.clue_1}</span>
                </div>
                <div className="answer">
                    <SvgIcon icon={Icon.Star} />
                    <span>{card.answer}</span>
                </div>
                <div className="number">{cardNumber}</div>
            </div>
        );
    }

    private renderCardButtons(): Array<JSX.Element> {
        const buttons: Array<JSX.Element> = [];
        for (let i: number = 1; i <= IKNOW_CARDS_NUMBER; i++) {
            const className: string = isCardDataCompleted(this.getCard(i)) ? 'card-number done' : 'card-number';
            buttons.push(<Button title={minDigits(i, 3)} className={className} key={i} onClick={() => this.showCardModalView(i)} />);
        }
        return buttons;
    }

    private showCardModalView(cardNumber: number): void {
        this.setState({
            viewMode: View.PreviewCard,
            selectedCardNumber: cardNumber
        });
    }

    private getCard(num: number): IKnowCard {
        return this.state.cards.find((f) => Number(f.number) === Number(num));
    }

    private updateCard(subCard: IKnowSubCard, cardNumber: number) {
        updateIKnowSubCardService(subCard)
            .then(() => {
                const cardsState: Array<IKnowCard> = this.state.cards;
                const cardState: IKnowCard = cardsState.find((card) => card.number === cardNumber);
                // todo temporary shitty solution
                if (cardState.culture.id === subCard.id) {
                    cardState.culture = subCard;
                } else if (cardState.history.id === subCard.id) {
                    cardState.history = subCard;
                } else if (cardState.world.id === subCard.id) {
                    cardState.world = subCard;
                } else if (cardState.people.id === subCard.id) {
                    cardState.people = subCard;
                }
                this.setState({cards: cardsState});
            })
            .catch((error: AxiosError) => alert(error.response.data));
    }
}
