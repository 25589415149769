export function scrollTo(element: HTMLElement): void {
    if (element) {
        element.scrollIntoView({block: 'center'});
        element.style.border = 'solid 3px sandybrown';

        setTimeout(() => {
            element.style.border = null;
            element = null;
        }, 2000);
    }
}
