import './_wishListPage.scss';
import {Button} from '@mp/common/components/button/ui/Button';
import {CheckBox} from '@mp/common/components/check-box/ui/CheckBox';
import {SelectSingleIcon} from '@mp/common/components/select-icon/ui/SelectSingleCategory';
import {TextInput} from '@mp/common/components/text-input/ui/TextInput';
import {ConfirmModal} from '@mp/common/modals/ConfirmModal';
import {Icon, SvgButton} from '@mp/common/svg';
import {WishListTab} from '@mp/route';
import React from 'react';
import {useParams} from 'react-router-dom';
import {CustomMenuElement, SettingMenu} from '../../../components/setting-menu/ui/SettingMenu';
import {TabsNavigation} from '../../../components/tabs-navigation/ui/TabsNavigation';
import {CATEGORY_ITEMS} from '../consts';
import {getUnknownCategory, resolveCategoryIconClass} from '../resolveCategory';
import {insertWishItemService, loadWishItemsService, updateWishItemBoughtService, updateWishItemService} from '../services';
import {WishItem} from '../types';
import {getNavigationTabs} from './utils/getNavigationTabs';

const MP_WISH_LIST = 'mp-wish-list-page';

interface WishListPageProps {
    selectedTab: WishListTab;
}

interface WishListPageState {
    wishItems: Array<WishItem>;
    buyConfirmationModalData: WishItem;
    addNewItemModalVisible: boolean;
}

export function WishListPage(): JSX.Element {
    const {tab} = useParams<{tab}>();

    return <WishListPageCC selectedTab={tab as WishListTab} />;
}

class WishListPageCC extends React.Component<WishListPageProps, WishListPageState> {
    constructor(props: WishListPageProps) {
        super(props);

        this.state = {
            wishItems: [],
            buyConfirmationModalData: null,
            addNewItemModalVisible: false
        };
    }

    public componentDidMount() {
        this.loadData();
    }

    private loadData(): void {
        loadWishItemsService().then((wishItems) => {
            this.setState({wishItems, buyConfirmationModalData: null, addNewItemModalVisible: false});
        });
    }

    public render() {
        const {selectedTab} = this.props;

        return (
            <MP_WISH_LIST>
                <div className={MP_WISH_LIST}>
                    <TabsNavigation
                        tabs={getNavigationTabs(selectedTab, this.state.wishItems)}
                        actions={[
                            {id: 'add-item', name: <SvgButton icon={Icon.Plus} color="white" />, onClick: () => this.onAddItemClick()}
                        ]}
                    />
                    {this.renderList()}
                </div>
                {this.ifRenderConfirmationBuyModal()}
                {this.ifRenderNewItemModal()}
            </MP_WISH_LIST>
        );
    }

    private ifRenderConfirmationBuyModal(): JSX.Element {
        if (this.state.buyConfirmationModalData == null) {
            return null;
        }

        const item = this.state.buyConfirmationModalData;

        return (
            <ConfirmModal
                title={item.name}
                question={`Czy na pewno chcesz oznaczyć "${item.name}" jako zakupione?`}
                handleConfirm={() => updateWishItemBoughtService(item).then(() => this.loadData())}
                handleClose={() => this.setState({buyConfirmationModalData: null})}
            />
        );
    }

    private ifRenderNewItemModal(): JSX.Element {
        if (!this.state.addNewItemModalVisible) {
            return null;
        }
        return (
            <ConfirmModal
                title={'Dodaj nowy przedmiot'}
                question={`Czy na pewno chcesz dodać nowy przedmiot?`}
                handleConfirm={() => insertWishItemService(getUnknownCategory()).then(() => this.loadData())}
                handleClose={() => this.setState({addNewItemModalVisible: false})}
            />
        );
    }

    private renderList(): JSX.Element {
        const {selectedTab} = this.props;
        const {wishItems} = this.state;
        const isBought: boolean = selectedTab === WishListTab.BOUGHT;

        const items: Array<WishItem> = wishItems.filter((f) => f.bought === isBought);

        return (
            <div className={`${MP_WISH_LIST}-list`}>
                <div className={`${MP_WISH_LIST}-list-conainer`}>{items.map((item) => this.renderListItem(item))}</div>
            </div>
        );
    }

    private renderListItem(item: WishItem): JSX.Element {
        const wishItemClass: string = `${MP_WISH_LIST}-item`;
        const customMenuElements: Array<CustomMenuElement> = [];

        if (item.isEditMode) {
            customMenuElements.push({label: 'Podgląd', onClick: () => this.onSettingMenuEditClick(item.id, false)});
        } else {
            customMenuElements.push({label: 'Edytuj', onClick: () => this.onSettingMenuEditClick(item.id, true)});
        }

        if (item.link) {
            customMenuElements.push({label: 'Link', onClick: () => this.onSettingMenuLinkClick(item.link)});
        }
        if (!item.bought) {
            customMenuElements.push({label: 'Kupiono', onClick: () => this.onSettingMenuBoughtClick(item)});
        }

        return (
            <div key={item.id} className={wishItemClass} onClick={null}>
                <div className={`${wishItemClass}-title`}>
                    <div className={`${wishItemClass}-title-left`}>
                        <SvgButton className="category" icon={resolveCategoryIconClass(item.categoryId)} />
                        {this.renderLink(item.link)}
                        <span>{item.name}</span>
                    </div>
                    <div className={`${wishItemClass}-title-right`}>
                        <span>{item.price} zł</span>
                        <SettingMenu title={item.name} menuWidth={120} customMenuElements={customMenuElements} />
                    </div>
                </div>
                {!item.isEditMode && this.renderDetailsView(item)}
                {item.isEditMode && this.renderEditView(item)}
            </div>
        );
    }

    private renderLink(link: string): JSX.Element {
        const linkIcon: JSX.Element = <SvgButton className="link" icon={Icon.Link} />;
        return link ? (
            <a href={link} target="_blank" rel="noreferrer">
                {linkIcon}
            </a>
        ) : (
            linkIcon
        );
    }

    private renderDetailsView(item: WishItem): JSX.Element {
        return <div className={`${MP_WISH_LIST}-item-description`}>Opis: {item.description || 'brak'}</div>;
    }

    private renderEditView(item: WishItem): JSX.Element {
        const nameInput: React.RefObject<HTMLInputElement> = React.createRef();
        const descriptionInput: React.RefObject<HTMLInputElement> = React.createRef();
        const priceInput: React.RefObject<HTMLInputElement> = React.createRef();
        const linkInput: React.RefObject<HTMLInputElement> = React.createRef();
        const boughtCheckbox: React.RefObject<HTMLInputElement> = React.createRef();
        let categoryId: number = null;

        function resolveWishItem(): WishItem {
            if (categoryId == null && item.categoryId === getUnknownCategory()) {
                alert('Niedozwolona kategoria');
                return null;
            }

            return {
                id: item.id,
                categoryId: categoryId ?? item.categoryId,
                name: nameInput.current.value,
                description: descriptionInput.current.value,
                price: Number(priceInput.current.value),
                link: linkInput.current.value,
                bought: boughtCheckbox.current.checked
            };
        }

        return (
            <>
                <SelectSingleIcon
                    title="Kategoria"
                    defaultCategoryId={item.categoryId}
                    onItemSelected={(id: number) => (categoryId = id)}
                    items={CATEGORY_ITEMS}
                />
                <TextInput title="Nazwa" defaultValue={item.name} reference={nameInput} />
                <TextInput title="Opis" defaultValue={item.description} reference={descriptionInput} />
                <TextInput title="Cena" defaultValue={item.price} reference={priceInput} inputMode="decimal" />
                <TextInput title="Link" defaultValue={item.link} reference={linkInput} />
                <CheckBox title="Kupiono" defaultChecked={item.bought} reference={boughtCheckbox} />
                <div className="button-container">
                    <Button title="Aktualizuj" onClick={() => this.onEditItemClick(resolveWishItem())} />
                </div>
            </>
        );
    }

    private onAddItemClick(): void {
        this.setState({addNewItemModalVisible: true});
    }

    private onEditItemClick(item: WishItem): void {
        if (item) {
            updateWishItemService(item).then(() => this.loadData());
        }
    }

    private onSettingMenuLinkClick(link: string): void {
        window.open(link, '_blank');
    }

    private onSettingMenuBoughtClick(item: WishItem): void {
        this.setState({buyConfirmationModalData: item});
    }

    private onSettingMenuEditClick(id: number, editMode: boolean): void {
        const foundItem: WishItem = this.state.wishItems.find((it) => it.id === id);
        foundItem.isEditMode = editMode;
        this.setState({wishItems: this.state.wishItems});
    }
}
