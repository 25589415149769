import {BigButton} from '@mp/common/components/button/ui/Button';
import {Item} from '@mp/common/components/suggestion-list/types';
import {SuggestionList} from '@mp/common/components/suggestion-list/ui/SuggestionList';
import {TextInput} from '@mp/common/components/text-input/ui/TextInput';
import classNames from 'classnames';
import React, {useContext, useEffect, useRef, useState, MutableRefObject} from 'react';
import {DEFAULT_INGREDIENT_SECTION} from '../consts';
import {RecipesPageContext} from '../context/RecipesPage.context';
import {Ingredient} from '../types';

export function IngredientEditRow({
    ingredient,
    recipeId,
    sectionInputRef,
    onAction
}: {
    ingredient: Ingredient | null;
    recipeId: number;
    sectionInputRef: MutableRefObject<HTMLInputElement>;
    onAction: (ingredient: Ingredient) => Promise<void>;
}): JSX.Element {
    ingredient = ingredient ?? ({} as Ingredient);
    const {shoppingItems} = useContext(RecipesPageContext);
    const [shoppingItem, setShoppingItem] = useState<Item>(null);
    const [editingDisabled, setEditingDisabled] = useState<boolean>(false);

    const valueInputRef: MutableRefObject<HTMLInputElement> = useRef(null);
    const unitInputRef: MutableRefObject<HTMLInputElement> = useRef(null);

    useEffect(() => {
        const foundItem: Item = shoppingItems.find(({id}) => id === ingredient.shoppingItemId);
        if (foundItem) {
            setShoppingItem(foundItem);
        }
    }, []);

    function isRowOk(): boolean {
        if (shoppingItem == null) {
            return !!ingredient.shoppingItemId;
        }
        return shoppingItem.id === ingredient.shoppingItemId;
    }

    return (
        <div key={ingredient.id} className={classNames('ingredient-edit-row', {'row-ok': isRowOk()})}>
            <SuggestionList
                items={shoppingItems}
                maxSearchResult={3}
                defaultSelectedId={ingredient.shoppingItemId}
                placeholder={ingredient.name ?? 'składniki'}
                onListItemClick={(item) => setShoppingItem(item)}
            />
            <TextInput name="value" reference={valueInputRef} defaultValue={ingredient.value} placeholder="ilość" maxLength={5} />
            <TextInput name="unit" reference={unitInputRef} defaultValue={ingredient.unit} placeholder="jednostka" maxLength={20} />
            <BigButton
                title={ingredient.id == null ? 'Dodaj' : 'Aktualizuj'}
                disabled={editingDisabled}
                onClick={() => {
                    setEditingDisabled(true);
                    const inputSection: string = sectionInputRef.current.value.trim();

                    if (!shoppingItem) {
                        alert('Nie znaleziono produktu w bazie produktów!');
                        setEditingDisabled(false);
                        return;
                    }

                    if (ingredient.section && ingredient.section.trim() !== inputSection) {
                        if (window.confirm(`Czy na pewno chcesz zaktualizować sekcje: "${ingredient.section}" na "${inputSection}"`)) {
                            executeAction();
                        } else {
                            setEditingDisabled(false);
                        }
                    } else {
                        executeAction();
                    }

                    function executeAction(): void {
                        onAction({
                            id: ingredient.id ?? null,
                            recipeId: recipeId,
                            shoppingItemId: shoppingItem.id,
                            section: inputSection || DEFAULT_INGREDIENT_SECTION,
                            name: shoppingItem.name,
                            unit: unitInputRef.current.value.trim().toLowerCase() || null,
                            value: Number(valueInputRef.current.value) || null
                        }).then(() => setEditingDisabled(false));
                    }
                }}
            />
        </div>
    );
}
