import {Icon, SvgIcon} from '@mp/common/svg';
import {isEmpty} from '@mp/common/utils/array';
import React from 'react';
import {CostEstimateWarning} from '../types';
import {createIdForItem, createIdForItemRef, createIdForSection} from '../utils/idGenerator';
import {scrollTo} from '../utils/scroll';

export function CostsEstimateWarnings({warnings}: {warnings: Array<CostEstimateWarning>}): JSX.Element {
    if (isEmpty(warnings)) {
        return null;
    }
    return (
        <div className="warnings">
            <div className="warnings-header">
                produkty wymagające uwagi: {warnings.length}
                <SvgIcon icon={Icon.TriangleExclamation} />
            </div>
            <div className="warnings-body">
                {warnings.map((data) => (
                    <div key={data.id} className="warnings-body-message" onClick={() => handleWarningClick(data)}>
                        <div>{data.name}</div>
                        <div id={createIdForItemRef(data.id)}>
                            {data.warnings.map((warning) => (
                                <div key={warning}>{warning}</div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

function handleWarningClick(data: CostEstimateWarning): void {
    const {id, costEstimateSectionId} = data;

    const getItemElement = (): HTMLElement => document.getElementById(createIdForItem(id));

    const itemElement: HTMLElement = getItemElement();

    if (itemElement) {
        scrollTo(itemElement);
    } else {
        const sectionElement: HTMLElement = document.getElementById(createIdForSection(costEstimateSectionId))
            ?.firstElementChild as HTMLElement;
        sectionElement.click();
        setTimeout(() => {
            scrollTo(getItemElement());
        }, 50);
    }
}
