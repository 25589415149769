import {service, ServiceResponseWithAffectedRow, ServiceResponseWithId} from '@mp/common/api';
import {SelectOption} from '@mp/common/components/form-input/types';
import {isEmpty} from '@mp/common/utils/array';
import {toDate} from '@mp/common/utils/converters';
import {convertToCurrency} from '@mp/common/utils/number';
import {CostEstimateItem, CostEstimateSection, CostEstimateWarning} from '../types';
import {getDescriptionByKey} from '../utils/dictionary';

const SERVICE_NAME = 'costsEstimateItems';

type CostEstimateSectionDto = Omit<CostEstimateSection, 'realCost' | 'items'>;
type CostEstimateItemDto = Omit<CostEstimateItem, 'warnings' | 'date'> & {date: string};

interface DTO {
    sections: Array<CostEstimateSectionDto>;
    items: Array<CostEstimateItemDto>;
}

let _sectionOptions: Array<SelectOption> = null;
export function getSectionOptions(): Array<SelectOption> {
    return _sectionOptions;
}

export async function loadCostsEstimateSectionAndItemsService(
    params: Pick<CostEstimateSection, 'costEstimateId'>
): Promise<Array<CostEstimateSection>> {
    return service.get<DTO>(SERVICE_NAME, {data: params}).then(({data}) => {
        const sections: Array<CostEstimateSection> = [];
        data.sections.forEach((sectionDto) => {
            const items: Array<CostEstimateItem> = (
                data.items.filter(({costEstimateSectionId}) => costEstimateSectionId === sectionDto.id) ?? []
            ).map(
                (rawItem): CostEstimateItem => ({
                    ...rawItem,
                    date: toDate(rawItem.date),
                    warning: resolveWarning(rawItem)
                })
            );

            sections.push({
                ...sectionDto,
                realCost: items.map(({price}) => price).reduce((a, b) => a + b, 0),
                items
            });
        });
        _sectionOptions = sections
            .map(({id, name}): SelectOption => ({name, value: String(id)}))
            .sort((a, b) => a.name.localeCompare(b.name));
        return sections;
    });
}

export function addCostsEstimateItemService(data: CostEstimateItem): Promise<ServiceResponseWithId> {
    return service.post(SERVICE_NAME, {data});
}

export function updateCostsEstimateItemService(data: CostEstimateItem): Promise<ServiceResponseWithAffectedRow> {
    return service.put(SERVICE_NAME, {data});
}

export function updateCostsEstimateItemImageService(data: {id: number; base64Image: string}): Promise<ServiceResponseWithAffectedRow> {
    return service.put(SERVICE_NAME, {data});
}

function resolveWarning(rawItem: CostEstimateItemDto): CostEstimateWarning {
    const warnings: Array<string> = [];
    const {id, costEstimateSectionId, name, date, price, costRefund, pricePaid} = rawItem;

    const pushEmptyFieldMessage = (key: keyof CostEstimateItem) => {
        warnings.push(`puste pole "${getDescriptionByKey(key)}"`);
    };

    if (price && pricePaid && price !== pricePaid) {
        warnings.push(
            `zapłacono ${convertToCurrency(pricePaid, true)} zalicznki. Pozostało do zapłaty: ${convertToCurrency(price - pricePaid, true)}`
        );
    }
    if (!date) {
        pushEmptyFieldMessage('date');
    }
    if (!price) {
        pushEmptyFieldMessage('price');
    }
    if (costRefund == null) {
        pushEmptyFieldMessage('costRefund');
    }
    if (!pricePaid) {
        pushEmptyFieldMessage('pricePaid');
    }

    if (isEmpty(warnings)) {
        return null;
    }
    return {id, name, costEstimateSectionId, warnings};
}
