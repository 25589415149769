import {CommonContext} from '@mp/common/context';
import {getUserProfile} from '@mp/common/cookies';
import {Icon, SvgButton} from '@mp/common/svg';
import {EventType} from '@mp/common/types';
import {toDateString} from '@mp/common/utils/converters';
import {clickOutside} from '@mp/common/utils/event';
import {isNotEmptyString} from '@mp/common/utils/string';
import {Router} from '@mp/route';
import React, {useContext, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {getLogs, isAdmin, isAdminOrLoggedUser, ORIGIN} from '../../global';
import {logoutService} from '../user/service/logout.service';
import {TileItemParams, tileItems} from './tileItems';

const MP_HEADER: string = 'mp-header';
const MP_HEADER_ICONS: string = 'mp-header-icons';
const MP_HEADER_RIGHT: string = 'mp-header-icons-right';
const MP_HEADER_USER_MENU: string = 'mp-header-user-menu';

interface HeaderState {
    userMenuOpened: boolean;
}

interface HeaderProps {
    itemIds: Array<number>;
    isEditMode: boolean;
    onEditModeClick: () => void;
}

export function Header(props: Pick<HeaderProps, 'itemIds'>): JSX.Element {
    const {context, setContext} = useContext(CommonContext);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    });

    const changeEditMode = (): void => {
        setContext({...context, isEditMode: !context.isEditMode});
    };

    function handleKeyDown(event: KeyboardEvent): void {
        if (event.key === 'e' && event.ctrlKey) {
            event.preventDefault();
            changeEditMode();
        }
    }

    return <HeaderCC itemIds={props.itemIds} isEditMode={context.isEditMode} onEditModeClick={changeEditMode} />;
}

export class HeaderCC extends React.Component<HeaderProps, HeaderState> {
    constructor(props: HeaderProps) {
        super(props);

        this.state = {userMenuOpened: false};
        this.changeUserMenuOpenedState = this.changeUserMenuOpenedState.bind(this);
        this.onOutsideClick = this.onOutsideClick.bind(this);
    }

    public componentDidUpdate(): void {
        if (this.state.userMenuOpened) {
            setTimeout(() => document.addEventListener(EventType.CLICK, this.onOutsideClick), 10);
        } else {
            document.removeEventListener(EventType.CLICK, this.onOutsideClick);
        }
    }

    public render() {
        const {name: userName, image: userImage} = getUserProfile();

        return (
            <div className={MP_HEADER}>
                {isAdminOrLoggedUser() && (
                    <>
                        <div className={MP_HEADER_ICONS}>
                            <ul>{this.getMenuItems()}</ul>
                            <div className={MP_HEADER_RIGHT}>
                                {userName && <div className="header-user-name">{userName}</div>}
                                {userImage ? (
                                    <img src={`${ORIGIN}/${userImage}`} onClick={this.changeUserMenuOpenedState} alt="u" />
                                ) : (
                                    <SvgButton icon={Icon.User} onClick={this.changeUserMenuOpenedState} />
                                )}
                            </div>
                        </div>
                        {this.state.userMenuOpened && this.renderUserMenu()}
                    </>
                )}
            </div>
        );
    }

    private onOutsideClick(event: Event) {
        if (clickOutside(event, MP_HEADER_USER_MENU)) {
            this.setState(() => ({userMenuOpened: false}));
        }
    }

    private renderUserMenu(): JSX.Element {
        const resolveLogs = () => {
            return getLogs()
                .map(({timestamp, level, message}) => `[${level}][${toDateString(timestamp, 'yyyy-mm-dd HH:MM:SS')}]\n${message}`)
                .join('\n\n');
        };

        const logs: string = resolveLogs();

        return (
            <div className={MP_HEADER_USER_MENU}>
                <Link to={Router.getUrlToSettingsPage()} onClick={this.changeUserMenuOpenedState}>
                    Ustawienia
                </Link>
                <div onClick={() => this.props.onEditModeClick()}>
                    {this.props.isEditMode ? 'Włącz tryb normalny' : 'Włącz tryb edycji'}
                </div>
                {isAdmin() && isNotEmptyString(logs) && <div onClick={() => alert(logs)}>Logi</div>}
                <div onClick={() => onLogout()}>Wyloguj</div>
            </div>
        );

        function onLogout(): void {
            logoutService().then(() => window.location.replace(Router.getUrlToLoginPage()));
        }
    }

    private changeUserMenuOpenedState() {
        this.setState((prevState: HeaderState) => ({userMenuOpened: !prevState.userMenuOpened}));
    }

    private getMenuItems(): JSX.Element {
        const headerItems: Array<TileItemParams> = this.props.itemIds.map((id) => tileItems.getAllTitleItems().find((f) => f.id === id));

        return <>{headerItems.map((tile) => this.renderMenuItem(tile))}</>;
    }

    private renderMenuItem(tileItem: TileItemParams): JSX.Element {
        return (
            <li key={tileItem.id}>
                <Link to={tileItem.route}>
                    <SvgButton icon={tileItem.svgIcon} />
                </Link>
            </li>
        );
    }
}
